import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/Seo'
export default function ProductDiscontinued({location}) {
  return (
    <Layout>
      <Seo title="404: Product Discontinued" location={location.pathname} />
      <h1>Product Discontinued</h1>
      <p>Unfortunately, we no longer carry this product. Please visit our <Link to="/products/">products</Link> page to see our available products.</p>
    </Layout>
  )
}
